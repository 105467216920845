

















































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import DateField from '@/components/inputs/DateField.vue'
import { vxm } from '@/store'
import b64toBlob from 'b64-to-blob'

@Component({
  components: {
    DateField,
  },
})
export default class Report extends Vue {
  private suppliers = []
  private supplierIds = []
  private isLoading = false
  private fromDate = ''
  private toDate = ''
  private submit = ''
  private data = []

  private mounted() {
    this.isLoading = true
    this.$axios
      .get('/v4/site/suppliers/connected')
      .then((response) => {
        this.suppliers = response.data.data || []
        this.isLoading = false
        this.onChangeUrl()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch suppliers')
        this.isLoading = false
      })
  }

  @Watch('$route', { deep: true })
  private onChangeUrl() {
    const tmp = ((this.$route.query?.supplierIds as string) || '').split(',')
    const changes = {
      fromDate: (this.$route.query?.fromDate as string) || '',
      toDate: (this.$route.query?.toDate as string) || '',
      supplierIds: tmp.map((v) => parseInt(v)),
      submit: (this.$route.query.submit as string) || '',
    }
    let changed = false
    if (changes.fromDate !== this.fromDate) {
      this.fromDate = changes.fromDate
      changed = true
    }
    if (changes.toDate !== this.toDate) {
      this.toDate = changes.toDate
      changed = true
    }
    if (JSON.stringify(changes.supplierIds) !== JSON.stringify(this.supplierIds)) {
      this.supplierIds = changes.supplierIds
      changed = true
    }
    if (changes.submit !== this.submit) {
      this.submit = changes.submit
      changed = true
    }
    if (changed && changes.submit) {
      this.load()
    }
  }

  private validate() {
    if (!this.fromDate || !this.toDate) {
      alert('Must provide from-date and to-date')
      return false
    }
    return true
  }

  private clickSubmit() {
    if (!this.validate()) {
      return
    }
    this.$router.push({
      name: 'Suppliers/Purchases/Reports/Report',
      query: {
        fromDate: this.fromDate,
        toDate: this.toDate,
        supplierIds: this.supplierIds.join(','),
        submit: new Date().getTime() + '',
      },
    })
  }

  private clickReset() {
    this.data = []
    this.$router.push({
      name: 'Suppliers/Purchases/Reports/Report',
    })
  }

  private clickExcel() {
    if (!this.validate()) {
      return
    }
    this.excel()
  }

  private makeUrl(isExcel) {
    let url =
      '/v4/site/suppliers/purchases' +
      (isExcel ? '/excel' : '') +
      '?fromDate=' +
      encodeURIComponent(this.fromDate) +
      '&toDate=' +
      encodeURIComponent(this.toDate)
    for (let i = 0; i < this.supplierIds.length; i++) {
      if (this.supplierIds[i] && !isNaN(this.supplierIds[i])) {
        url += '&supplierIds[]=' + encodeURIComponent(this.supplierIds[i])
      }
    }
    return url
  }

  private excel() {
    this.isLoading = true
    const url = this.makeUrl(true)
    this.$axios
      .get(url)
      .then((response) => {
        const filename = 'SupplierPurchases.xlsx'
        const blob = b64toBlob(response.data.data.data, 'application/vnd.ms-excel')
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch purchases data')
        this.isLoading = false
      })
  }

  private load() {
    this.isLoading = true
    const url = this.makeUrl(false)
    this.$axios
      .get(url)
      .then((response) => {
        this.data = response.data.data
        this.isLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Could not fetch purchases data')
        this.isLoading = false
      })
  }

  private get hasData() {
    return this.data && this.data.length > 0
  }

  private get hasSubmit() {
    return !!this.$route.query.submit
  }
}
